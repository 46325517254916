import * as d3 from 'd3';
// import bb, { bar, pie, area, line, spline, step, areaLineRange, areaStepRange, areaSplineRange, bubble } from "https://unpkg.com/billboard.js/dist-esm/billboard.js";
import bb, {
  area,
  areaStepRange,
  bar,
  line,
  pie,
  scatter,
  spline,
} from 'billboard.js'

export default class Figures {
  static d3  = d3
  static bb  = bb
  static types = {
    area: area,
    areaStepRange: areaStepRange,
    bar: bar,
    line: line,
    pie: pie,
    scatter: scatter,
    spline: spline
  }
}

window.Figures = Figures

export { Figures }
