import { Controller } from "@hotwired/stimulus"
import ValueParser from "../../utils/value_parser"

// Connects to data-controller="calc--row"
export default class extends Controller {
  static targets = ['count', 'amount', 'total', 'change']

  static values = {
    baseData: { type: Object, default: {} }
  }

  static classes = ['disabledField', 'highlightedField']

  connect() {
    app.calc_rows ??= []
    app.calc_rows.push(this)
  }

  giftUpdate(event) {
    this.updateTotalByGifts()
    this.highlightGiftFields()
    this.disableChangeField()
    this.announceChange()
  }

  changeUpdate(event) {
    this.updateTotalByChange()
    this.highlightChangeField()
    this.disableGiftFields()
    this.announceChange()
  }

  targetUpdate(event) {
    this.disableGiftFields()
    this.disableChangeField()
    this.announceChange()
  }

  // update(event) {
  //   if (this.isGiftTarget(event.target)) {
  //     this.updateTotalByGifts()
  //     this.announceChange()
  //     // highlight gift targets
  //     // disable change target
  //   } else if (this.isChangeTarget(event.target)) {
  //     this.updateTotalByChange()
  //     this.disableGiftFields()
  //   } else if (this.isTotalTarget(event.target)) {
  //     // update whole, disable inputs
  //     console.log('row total update');
  //   }
  // }

  disableGiftFields() {
    this.countTarget.classList.remove(...this.highlightClasses)
    this.amountTarget.classList.remove(...this.highlightClasses)
    this.countTarget.classList.add(...this.disableClasses)
    this.amountTarget.classList.add(...this.disableClasses)
  }

  highlightGiftFields() {
    this.countTarget.classList.remove(...this.disableClasses)
    this.amountTarget.classList.remove(...this.disableClasses)
    this.countTarget.classList.add(...this.highlightClasses)
    this.amountTarget.classList.add(...this.highlightClasses)
  }

  disableChangeField() {
    this.changeTarget.classList.remove(...this.highlightClasses)
    this.changeTarget.classList.add(...this.disableClasses)
  }

  highlightChangeField() {
    this.changeTarget.classList.add(...this.highlightClasses)
    this.changeTarget.classList.remove(...this.disableClasses)
  }

  isGiftTarget(tgt) {
    return tgt === this.countTarget || tgt === this.amountTarget
  }

  isChangeTarget(tgt) {
    return tgt === this.changeTarget
  }

  isTotalTarget(tgt) {
    return tgt === this.totalTarget
  }

  updateTotalByGifts() {
    const count  = this.#parseCount(this.countTarget.value)
    const amount = this.#parseMoney(this.amountTarget.value)

    this.totalTarget.value = count * amount
  }

  updateTotalByChange() {
    const pct = this.#parsePercent(this.changeTarget.value)

    this.totalTarget.value = pct * this.baseData.total
  }

  announceChange() {
    this.dispatch("changed")
  }

  // Data

  get baseData() {
    return {
      avg: this.#parseMoney(this.baseDataValue.amount_avg),
      total: this.#parseMoney(this.baseDataValue.amount_sum),
      count: this.#parseCount(this.baseDataValue.gifts),
      change: this.#parsePercent(this.baseDataValue.yoy_change)
    }
  }

  // Utils

  get disableClasses() {
    // return ['text-slate-400']
    return this.disabledFieldClasses
  }

  get highlightClasses() {
    // return ['text-sky-800', 'bg-sky-50']
    return this.highlightedFieldClasses
  }

  #parseCount(str) {
    return ValueParser.castNumber(str)
  }

  #parseMoney(str) {
    return ValueParser.parseCurrency(str)
  }

  #parsePercent(str) {
    return ValueParser.parsePercent(str)
  }
}
